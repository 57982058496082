main {
  margin: 0 auto;
  max-width: 90%;
  padding: 50px 30px;
}

:root {
  --primary-color: #062f4f;
  --secondary-color: #B82601;
  --tertiary: #813772;
  --accent-color: #43c0f6;
  --dark: #000000;
  --text-color: #F5F5F5;
  --spacing-one: calc(1rem * 0.05);
  --spacing-two: calc(1rem);
  --spacing-four: calc(2rem);
  --spacing-10: calc(5rem);
}

* {
  box-sizing: border-box;
  background-color: var(--primary-color);
}

h1,
h2,
h4,
h6 {
  color: var(--text-color);
}

html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  letter-spacing: .1em;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

.nav {
  display: flex;
  justify-content: flex-end;
  background-image: '/assets/images/website-Design-Background.png';
}

.nav-item {
  color: var(--text-color);
  font-weight: bold;
  font-size: 36px;
  padding: 10px 30px 10px 30px;
}

.navActive {
  color: var(--secondary-color);
}

.site-logo {
  padding-left: 50px;
  height: 5%;
  width: 5%;
  border-radius: 20px;
}

.header-JP {
  flex-direction:row;
  margin-left: 25px;
  font-size: 2.25rem;
}

li {
  list-style: none;  
}

nav a:hover {
  text-decoration: none;
}

nav  li {
  cursor: pointer;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

span {
  display: inline-block;
  padding: 0 16px 0 16px;
  color: var(--text-color);
  font-size: 24px;
  font-weight: bold;
}

code {
  font-family: 'Open Sans', sans-serif;
}

.about-container {
  display: flex;
  justify-content: space-evenly;
  padding: 50px 100px 50px 80px;
}

.about-me {
  color: var(--text-color);
  display: column;
  justify-content: space-evenly;
  align-items: left;
  font-size: 1.75rem;
  word-spacing: var(--spacing-two);
  padding: 0 40px;
  margin: 5px 0;
  line-height: 1.25;
}

.profile-img {
  width: 25%;
  border-radius: 20px;
  box-shadow: 0 0 60px rgba(0,0,0,0.15);
}

.salutation {
  font-size: 3.25rem;
  font-weight: bold;
}

.hero-img {
  width: 0%;
  height: 0%;
  border-radius: 20px;
  align-content: center;
}

.social-tags {
  color: var(--text-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.social-tags li {
  color: var(--accent-color);
  font-weight: bold;
  font-size: 18px;
}

.contact-container {
  margin-left: 100px;
  padding: 30px;
  align-content: center;
  border: 2px solid var(--accent-color);
  border-radius: 20px;
  width: 30%;
}

.project-container {
  width: 100%;
}

.project-header h1 {
  font-size: 42px;
}

.project-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.project-card {
  position: relative;
  background-color: var(--secondary-color);
  width: 30%;
  height: 50rem;
  border-radius: 20px;
  box-shadow: 0 0 60px rgba(0,0,0,0.15);
  display: block;
  margin: 20px;
  padding: 10px;
  flex-direction:row;
  align-items: flex-start;
}

.project-title {
  font-size: 32px;
  background-color: var(--secondary-color);
  text-align: center;
}

.img-container {
  border-radius: 20px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 0 40px 5px rgba(0,0,0,0.2);
}

.project-img {
  width: 550px;
  height: 400px;
  border-radius: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.project-description {
  font-size: 1.2rem;
  word-spacing: var(--spacing-one);
  font-weight:lighter;
  background-color: var(--secondary-color);
  text-align: center;
}

.tags {
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.tags h6 {
  border: none;
  border-radius: 20px;
  width: 25%;
  height: 10%;
  font-size: 14px;
  padding: 7px;
  margin: 10px;
  text-align:center;
  font-weight:bold;
  background-color: var(--accent-color);
  color: var(--primary-color);
  box-shadow: 0 0 40px rgba(0,0,0,0.2);
}

.project-links {
  background-color: var(--secondary-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.project-links a {
  color: var(--accent-color);
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  background-color: var(--secondary-color);
}

.experience {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px auto;
}

.bio-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  padding: 0 30px 0 30px;
}

.bio-container h1 {
  font-size: 42px;
}

.bio-img {
  border-radius: 20px;
  width: 450px;
  height: 600px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin-bottom: 30px;
}

.button {
  background-color: var(--secondary-color);
  color: var(--accent-color);
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  width: 100px;

}

.view-btn:hover {
  color: var(--secondary-color);
  cursor: pointer;
}

.skills {
  color: var(--text-color);
  width: 50%;

}

.skills-list {
  list-style-type: circle;
  word-spacing: var(--spacing-four);
  padding: 15px;
  line-break: var(--spacing-four);
  font-size: 28px;
  margin: 10px 0;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.skills-list::before {
  list-style-type: circle;
  color: var(--accent-color);
}

.skills-list:not(:last-child) {
  margin-bottom: 5px;
}

.contact-header {
  font-size: 42px;
}

form {
  width: 40%;
}

.contact-form {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: 10px;
}

label {
  color: var(--text-color);
  font-size: 28px;
  line-height:var(--spacing-ten);
}

input {
  color: var(--text-color);
  font-size: 20px;
  align-items:flex-end;
}

textarea {
  color: var(--text-color);
  font-size: 20px;
}

.error-text {
  color: var(--accent-color);
  font-size: 24px;
  font-weight: bold;
}

.modalBackdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}

.modalContainer {
	background: var(--darker);
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 960px;
}

.modalTitle {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}

footer {
  padding: 40px 35px;
  border-radius: 20%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 980px) {
  .nav-item {
    font-size: 24px;
    padding: 5px 10px 0 10px;

  }

  .about-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
  }

  .profile-img {
    width: 50%;
    height: 50%;
  }

  .social-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .project-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
  }

  .project.cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .project-img { 
    width: 30%;
    height: 20%
  }

  .experience {
    display: flex;
    flex-wrap: wrap;
  }
}
